<template>
  <b-row>
    <b-col cols="12">
      <b-row>
        <b-col cols="8">
          <invoices :organizationId="organizationId"></invoices>
        </b-col>
        <b-col cols="4">
          <subscription :organizationId="organizationId"></subscription>
          <payment-profiles :organizationId="organizationId"></payment-profiles>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import { mapGetters } from 'vuex'
import Invoices from './components/Invoices'
import PaymentProfiles from './components/PaymentProfiles'
import Subscription from './components/Subscription'
import moment from 'moment'

export default {
  name: 'Billing',
  components: {
    Invoices,
    PaymentProfiles,
    Subscription
  },
  data: () => {
    return {}
  },
  computed: {
    ...mapGetters({
      account: 'global/account'
    }),
    organizationId () {
      if (this.account) {
        return this.account.organizationId
      } else {
        return null
      }
    }
  },
  methods: {
  }
}
</script>
